export const label = {
  AddNewPage: {
    title: "label.AddNewPage.title",
    information: "label.AddNewPage.information",
    name: "label.AddNewPage.name",
    Template: {
      title: "label.AddNewPage.Template.title",
      BaseReport: {
        title: "label.AddNewPage.Template.BaseReport.title",
        content: "label.AddNewPage.Template.BaseReport.content"
      },
      BlankReport: {
        title: "label.AddNewPage.Template.BlankReport.title",
        content: "label.AddNewPage.Template.BlankReport.content"
      }
    },
    Save: {
      title: "label.AddNewPage.Save.title"
    }
  },
  Modals: {
    DiscardChanges: {
      title: "label.Modals.DiscardChanges.title",
      content: "label.Modals.DiscardChanges.content",
      cancel: "label.Modals.DiscardChanges.cancel",
      discard: "label.Modals.DiscardChanges.discard"
    },
    DeleteFile: {
      title: "label.Modals.DeleteFile.title",
      content: "label.Modals.DeleteFile.content",
      cancel: "label.Modals.DeleteFile.cancel",
      delete: "label.Modals.DeleteFile.delete"
    }
  },
  EditViewReportPage: {
    RotateDevice: {
      title: "label.EditViewReportPage.RotateDevice.title"
    },
    Edit: {
      title: "label.EditViewReportPage.Edit.title"
    },
    Save: {
      title: "label.EditViewReportPage.Save.title"
    }
  },
  TopMenu: {
    Search: "label.TopMenu.Search"
  },
  UserMenu: {
    language: {
      title: "label.UserMenu.language.title",
      english: "label.UserMenu.language.english",
      portuguese: "label.UserMenu.language.portuguese",
      spanish: "label.UserMenu.language.spanish"
    },
    timezone: "label.UserMenu.timezone",
    theme: {
      title: "label.UserMenu.theme.title",
      dark: "label.UserMenu.theme.dark",
      light: "label.UserMenu.theme.light"
    },
    signout: "label.UserMenu.signout"
  },
  ReportsPage: {
    title: "label.ReportsPage.title",
    AddReportDesktop: {
      title: "label.ReportsPage.AddReportDesktop.title"
    },
    AddReportMobile: {
      title: "label.ReportsPage.AddReportMobile.title"
    },
    AddReport: {
      title: "label.ReportsPage.AddReport.title"
    },
    ButtonFilter: {
      Name: {
        title: "label.ReportsPage.ButtonFilter.Name.title"
      },
      Owner: {
        title: "label.ReportsPage.ButtonFilter.Owner.title"
      },
      DateModified: {
        title: "label.ReportsPage.ButtonFilter.DateModified.title"
      }
    },
    FilteLimitReached: {
      title: "label.ReportsPage.FilteLimitReached.title"
    }
  }
}