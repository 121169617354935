import { defineStore } from "pinia";

export interface AppConfig {
  authFlow: string;
  authRedirectOne: string;
  authTenant: string;
  authUrl: string;
}

export const useOneStore = defineStore('store-one', {
  state: () => ({
    apps: [] as NewMenu.Link[],
    config: {
      authFlow: "",
      authRedirectOne: "",
      authTenant: "",
      authUrl: "",
    }
  }),
  actions: {
    loadApps(apps: NewMenu.Link[]) {
      this.apps = apps
    },
    loadConfig(config: AppConfig) {
      this.config = { ...this.config, ...config }
    }

  },
})


