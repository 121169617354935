export const appsExample: NewMenu.Link[] = [
  {
    id: "Apps",
    label: "App1",
    routerName: "App1View",
    order: 1,
    subLinks: [],
  },
  {
    id: "Apps",
    label: "App2",
    routerName: "App2View",
    order: 2,
    subLinks: [],
  },
  {
    id: "Apps",
    label: "App3",
    routerName: "App3View",
    order: 3,
    subLinks: [],
  }
]
