import { computed, ref } from "vue";

export function useCustomMenu({ emits, model, props }: NewMenu.UseCustomMenuProps) {
  const currentLinksRef = ref<NewMenu.Link[][]>([]);
  const renderCurrentLinks = computed(() => {
    if (currentLinksRef.value[currentLinksRef.value.length - 1])
      return currentLinksRef.value[currentLinksRef.value.length - 1];
    return props.links;
  });

  function handleCloseMenu() {
    model.value = false;
    currentLinksRef.value = [];
  }

  function handleOpenMenu() {
    model.value = true;
  }

  function handleRouterTo(app: NewMenu.Link) {
    const subLinkHasBackMenu =
      app.subLinks.length <= 0 && app.routerName.length <= 0 && app.order === 1;

    const hasSubLinks = app.subLinks.length > 0;
    const hasRouterName = app.routerName.length > 0;

    if (hasSubLinks) {
      currentLinksRef.value.push(app.subLinks);
      return;
    }

    if (hasRouterName) {
      emits("routerTo", app);
      handleCloseMenu();
      currentLinksRef.value = [];
      return;
    }

    if (subLinkHasBackMenu) {
      currentLinksRef.value.pop();
      return;
    }
    currentLinksRef.value.pop();
    emits("selectLink", app);
  }

  return {
    handleCloseMenu,
    handleOpenMenu,
    currentLinksRef,
    renderCurrentLinks,
    handleRouterTo
  }
}
