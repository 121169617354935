export const label = {
  TopMenu: {
    Search: "Search on ClearSale"
  },
  UserMenu: {
    language: {
      title: "Language",
      english: "English",
      portuguese: "Português",
      spanish: "Español"
    },
    timezone: "Timezone",
    theme: {
      title: "Theme",
      dark: "Dark",
      light: "Light"
    },
    signout: "Sign Out"
  },
}
