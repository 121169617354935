import { customEvents } from "@clearsale/one-lib-events";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
export const routeNames = {
  transactionView: "ShieldView",
  transactionsDetails: "transactionDetailView",
  preview: "preview",
};

const routes: Array<RouteRecordRaw> = [];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  customEvents.menuEvent.displayToggled.dispatch(true);
});

export default router;
