import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import AppsMenuDesktop from "./AppsMenuDesktop.vue";
import AppsMenuMobile from "./AppsMenuMobile.vue";
import { getApps } from "@/api/resources/oneResource";
import { useOneStore } from "@/store/OneStore";

import NexusLogo from "@/assets/logos/logo_nexus.svg";
import ShieldLogo from "@/assets/logos/logo_shield.svg";
import { customEvents } from "@clearsale/one-lib-events";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppsMenu',
  props: {
    "modelValue": { default: false },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const { mdAndUp } = useDisplay();

const model = _useModel(__props, "modelValue");
const openAppsMenu = ref(model);

const loading = ref(true);

const allApps = [
  {
    id: "Apps",
    label: "One",
    routerName: "OneView",
    order: 1,
    subLinks: [],
    description: "",
  },
  {
    id: "Apps",
    label: "Shield",
    routerName: "ShieldView",
    order: 2,
    img: ShieldLogo,
    subLinks: [],
    description: "Intelligence in fraud analysis",
  },
  {
    id: "Apps",
    label: "Nexus",
    routerName: "NexusView",
    order: 3,
    img: NexusLogo,
    subLinks: [],
    description: "Fraud analysis visualization",
  },
];

const links = ref<NewMenu.Link[]>([]);

const { loadApps } = useOneStore();

const routerMap: AppsMenu.SelectRouter = {
  NexusView: "/nexus",
  ShieldView: "/shield",
  OneView: "/app",
};

function handleSelectRouterTo(link: NewMenu.Link) {
  customEvents.routerEvent.dispatch(
    routerMap[link.routerName as AppsMenu.AppView_Type]
  );
}

function loadAppsIsShow() {
  getApps()
    .then((res) => {
      const appsAllowed = allApps.filter((app) =>
        res.results.includes(app.label)
      );
      links.value = appsAllowed;

      loadApps(links.value);
    })
    .finally(() => {
      loading.value = false;
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", { onClick: loadAppsIsShow }, [
    (_unref(mdAndUp))
      ? (_openBlock(), _createBlock(AppsMenuDesktop, {
          key: 0,
          modelValue: openAppsMenu.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((openAppsMenu).value = $event)),
          links: links.value.length > 0 ? links.value : [],
          onRouterTo: handleSelectRouterTo
        }, null, 8, ["modelValue", "links"]))
      : (_openBlock(), _createBlock(AppsMenuMobile, {
          key: 1,
          modelValue: openAppsMenu.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((openAppsMenu).value = $event)),
          links: links.value.length > 0 ? links.value : [],
          onRouterTo: handleSelectRouterTo
        }, null, 8, ["modelValue", "links"]))
  ]))
}
}

})