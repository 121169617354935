<template>
  <div v-click-outside="handleCloseMenu">
    <v-btn
      icon
      variant="text"
      color="cs_surface_variant"
      @click.stop="modelMenu = !modelMenu"
    >
      <v-icon
        icon="mdi-account-circle"
        class="text-cs_on_surface_variant"
      ></v-icon>
    </v-btn>

    <Transition name="fade">
      <div v-if="modelMenu" class="cs__menu w-100 d-flex flex-column">
        <div class="d-flex flex-column rounded-lg overflow-y-auto">
          <slot>
            <template v-for="link in renderCurrentLinks" :key="link.label">
              <slot
                v-if="hero && link.order && link.order === 1"
                name="hero"
                classStyle="pa-4 bg-cs_secondary_container cursor-pointer"
              >
                <div
                  @click.stop="handleRouterTo(link)"
                  class="pa-4 bg-cs_secondary_container cursor-pointer d-flex align-center justify-space-between"
                  :class="`order-${link.order}`"
                >
                  <div class="d-flex align-center">
                    <div v-if="currentLinksRef.length > 0">
                      <v-icon icon="mdi-menu-left"></v-icon>
                    </div>
                    <div
                      class="d-flex align-center ga-4"
                      :class="link.className"
                    >
                      <img
                        :src="link.img.name"
                        alt="img"
                        :width="link.img.size"
                        :height="link.img.size"
                        v-if="link.img"
                      />
                      <v-icon
                        v-else-if="link.icon"
                        :icon="link.icon.name"
                        :size="link.icon.size"
                      ></v-icon>
                      <div>
                        {{ link.label }}
                      </div>
                    </div>
                  </div>
                  <div v-if="link.subLinks.length">
                    <v-icon icon="mdi-menu-right"></v-icon>
                  </div>
                </div>
              </slot>
              <v-divider
                v-else-if="link.id === 'separator'"
                :class="`order-${link.order}`"
                :thickness="1"
              ></v-divider>
              <slot
                name="items"
                :links="renderCurrentLinks"
                :link="link"
                classStyle="pa-4 bg-surface-variant  cursor-pointer"
                v-else
              >
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <div
                      v-bind="props"
                      @click.stop="handleRouterTo(link)"
                      class="pa-4 bg-surface-variant cursor-pointer d-flex align-center flex-column"
                      :class="[
                        `order-${link.order}`,
                        { 'bg-cs_surface_container': isHovering },
                      ]"
                    >
                      <div
                        class="d-flex align-center justify-space-between w-100"
                        :class="link.className"
                      >
                        <div class="d-flex align-center ga-2">
                          <v-icon
                            v-if="link.icon"
                            :icon="link.icon ? link.icon.name : ''"
                            :size="link.icon ? link.icon.size : '24px'"
                          ></v-icon>
                          <div class="d-flex align-center">
                            {{ link.label }}
                          </div>
                        </div>
                        <div v-if="link.subLinks.length">
                          <v-icon icon="mdi-menu-right"></v-icon>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-hover>
              </slot>
            </template>
          </slot>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import { linksExample } from "../constants/Menu";
import { useCustomMenu } from "../useCustomMenu";

const props = withDefaults(
  defineProps<{
    links: NewMenu.Link[];
    hero: boolean;
  }>(),
  {
    links: () => linksExample,
    hero: true,
  }
);

const emits = defineEmits<{
  (e: "routerTo", routeApp: NewMenu.Link): void;
  (e: "selectLink", link: NewMenu.Link): void;
}>();

const modelMenu = defineModel({ default: false });
const { currentLinksRef, handleCloseMenu, handleRouterTo, renderCurrentLinks } =
  useCustomMenu({ emits, props, model: modelMenu });
</script>

<style scoped>
.cs__menu {
  max-width: 240px;
  position: absolute;
  z-index: 999;
  right: 16px;
  top: 48px;
  transition: opacity 0.3s;
  max-height: 558px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
