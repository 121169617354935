import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cs__overlay"
}
const _hoisted_2 = {
  key: 0,
  class: "cs__menu d-flex flex-column flex-wrap ga-2 bg-surface-variant",
  style: {"border-radius":"19px 0px 0px 16px","z-index":"9999"}
}
const _hoisted_3 = {
  class: "d-flex flex-column overflow-y-auto",
  style: {"min-width":"240px","border-radius":"19px 0px 0px 0px","height":"100%"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "d-flex align-center" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src", "width", "height"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "d-flex align-center ga-2" }
const _hoisted_11 = { class: "d-flex align-center" }
const _hoisted_12 = { key: 0 }

import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import { linksExample } from "../constants/Menu";
import { useCustomMenu } from "../useCustomMenu";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomMenuMobile',
  props: /*@__PURE__*/_mergeModels({
    links: { default: () => linksExample },
    hero: { type: Boolean, default: true }
  }, {
    "modelValue": { default: false },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["routerTo", "selectLink"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const modelMenu = _useModel(__props, "modelValue");

const { currentLinksRef, handleCloseMenu, handleRouterTo, renderCurrentLinks } =
  useCustomMenu({ props, emits, model: modelMenu });

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      icon: "",
      variant: "text",
      color: "cs_surface_variant",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (modelMenu.value = !modelMenu.value), ["stop"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          icon: "mdi-account-circle",
          class: "text-cs_on_surface_variant"
        })
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (modelMenu.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "open" }, {
        default: _withCtx(() => [
          (modelMenu.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default", {}, () => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(renderCurrentLinks), (link) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: link.label
                      }, [
                        (_ctx.hero && link.order && link.order === 1)
                          ? _renderSlot(_ctx.$slots, "hero", {
                              key: 0,
                              classStyle: "pa-4 bg-cs_secondary_container cursor-pointer"
                            }, () => [
                              _createElementVNode("div", {
                                onClick: _withModifiers(($event: any) => (_unref(handleRouterTo)(link)), ["stop"]),
                                class: _normalizeClass(["pa-4 bg-cs_secondary_container cursor-pointer d-flex align-center justify-space-between", `order-${link.order}`])
                              }, [
                                _createElementVNode("div", _hoisted_5, [
                                  (_unref(currentLinksRef).length > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                        _createVNode(_component_v_icon, { icon: "mdi-menu-left" })
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["d-flex align-center ga-4", link.className])
                                  }, [
                                    (link.img)
                                      ? (_openBlock(), _createElementBlock("img", {
                                          key: 0,
                                          src: link.img.name,
                                          alt: "img",
                                          width: link.img.size,
                                          height: link.img.size
                                        }, null, 8, _hoisted_7))
                                      : (link.icon)
                                        ? (_openBlock(), _createBlock(_component_v_icon, {
                                            key: 1,
                                            icon: link.icon.name,
                                            size: link.icon.size
                                          }, null, 8, ["icon", "size"]))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("div", null, _toDisplayString(link.label), 1)
                                  ], 2)
                                ]),
                                (link.subLinks.length)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                      _createVNode(_component_v_icon, { icon: "mdi-menu-right" })
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 10, _hoisted_4)
                            ])
                          : (link.id === 'separator')
                            ? (_openBlock(), _createBlock(_component_v_divider, {
                                key: 1,
                                class: _normalizeClass(`order-${link.order}`),
                                thickness: 2
                              }, null, 8, ["class"]))
                            : (link.id === 'space')
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: _normalizeClass(`order-${link.order}`),
                                  style: {"flex":"1"}
                                }, null, 2))
                              : _renderSlot(_ctx.$slots, "items", {
                                  key: 3,
                                  links: _unref(renderCurrentLinks),
                                  link: link,
                                  classStyle: "pa-4 bg-surface-variant  cursor-pointer"
                                }, () => [
                                  _createElementVNode("div", {
                                    onClick: _withModifiers(($event: any) => (_unref(handleRouterTo)(link)), ["stop"]),
                                    class: _normalizeClass(["pa-4 bg-surface-variant cursor-pointer d-flex align-center", `order-${link.order}`])
                                  }, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["d-flex align-center justify-space-between w-100", link.className])
                                    }, [
                                      _createElementVNode("div", _hoisted_10, [
                                        (link.icon)
                                          ? (_openBlock(), _createBlock(_component_v_icon, {
                                              key: 0,
                                              icon: link.icon ? link.icon.name : '',
                                              size: link.icon ? link.icon.size : '24px'
                                            }, null, 8, ["icon", "size"]))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_11, _toDisplayString(link.label), 1)
                                      ]),
                                      (link.subLinks.length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                            _createVNode(_component_v_icon, { icon: "mdi-menu-right" })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 2)
                                  ], 10, _hoisted_9)
                                ])
                      ], 64))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]))
  ])), [
    [_unref(vClickOutside), _unref(handleCloseMenu)]
  ])
}
}

})