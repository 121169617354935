import { DirectiveBinding, nextTick } from "vue";

export const vClickOutside = {
  beforeMount(el: NewMenu.ClickOutsideElement, binding: DirectiveBinding) {
    el.clickOutsideEvent = (event: MouseEvent) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event);

      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);

  },
  updated(el: NewMenu.ClickOutsideElement, binding: DirectiveBinding) {
    // Remove o evento antigo
    if (el.clickOutsideEvent) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    }

    nextTick(() => { // Aguarda a próxima atualização do DOM
      // Adiciona o evento novamente
      el.clickOutsideEvent = (event: MouseEvent) => {
        if (!(el === event.target || el.contains(event.target as Node))) {
          binding.value(event);
        }
      };
      document.body.addEventListener("click",
        el.clickOutsideEvent);
    });
  },
  unmounted(el:
    NewMenu.ClickOutsideElement) {
    if (el.clickOutsideEvent)
      document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};

