export const timezoneCityCountry = {
  "utc_timezones": [
    {
      "utc_offset": "UTC-12:00",
      "city": "Baker Island",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-11:00",
      "city": "Pago Pago",
      "country": "American Samoa"
    },
    {
      "utc_offset": "UTC-11:00",
      "city": "Midway Atoll",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-10:00",
      "city": "Honolulu",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-10:00",
      "city": "Papeete",
      "country": "French Polynesia"
    },
    {
      "utc_offset": "UTC-09:00",
      "city": "Anchorage",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-08:00",
      "city": "Los Angeles",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-08:00",
      "city": "Vancouver",
      "country": "Canada"
    },
    {
      "utc_offset": "UTC-07:00",
      "city": "Denver",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-07:00",
      "city": "Chihuahua",
      "country": "Mexico"
    },
    {
      "utc_offset": "UTC-06:00",
      "city": "Chicago",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-06:00",
      "city": "Mexico City",
      "country": "Mexico"
    },
    {
      "utc_offset": "UTC-05:00",
      "city": "New York",
      "country": "United States"
    },
    {
      "utc_offset": "UTC-05:00",
      "city": "Bogotá",
      "country": "Colombia"
    },
    {
      "utc_offset": "UTC-05:00",
      "city": "Lima",
      "country": "Peru"
    },
    {
      "utc_offset": "UTC-04:00",
      "city": "Caracas",
      "country": "Venezuela"
    },
    {
      "utc_offset": "UTC-04:00",
      "city": "San Juan",
      "country": "Puerto Rico"
    },
    {
      "utc_offset": "UTC-03:00",
      "city": "Buenos Aires",
      "country": "Argentina"
    },
    {
      "utc_offset": "UTC-03:00",
      "city": "São Paulo",
      "country": "Brazil"
    },
    {
      "utc_offset": "UTC-02:00",
      "city": "Noronha",
      "country": "Brazil"
    },
    {
      "utc_offset": "UTC-01:00",
      "city": "Praia",
      "country": "Cape Verde"
    },
    {
      "utc_offset": "UTC±00:00",
      "city": "London",
      "country": "United Kingdom"
    },
    {
      "utc_offset": "UTC±00:00",
      "city": "Lisbon",
      "country": "Portugal"
    },
    {
      "utc_offset": "UTC+01:00",
      "city": "Berlin",
      "country": "Germany"
    },
    {
      "utc_offset": "UTC+01:00",
      "city": "Paris",
      "country": "France"
    },
    {
      "utc_offset": "UTC+02:00",
      "city": "Athens",
      "country": "Greece"
    },
    {
      "utc_offset": "UTC+02:00",
      "city": "Tel Aviv",
      "country": "Israel"
    },
    {
      "utc_offset": "UTC+03:00",
      "city": "Riyadh",
      "country": "Saudi Arabia"
    },
    {
      "utc_offset": "UTC+03:00",
      "city": "Moscow",
      "country": "Russia"
    },
    {
      "utc_offset": "UTC+04:00",
      "city": "Dubai",
      "country": "United Arab Emirates"
    },
    {
      "utc_offset": "UTC+04:00",
      "city": "Muscat",
      "country": "Oman"
    },
    {
      "utc_offset": "UTC+05:00",
      "city": "Karachi",
      "country": "Pakistan"
    },
    {
      "utc_offset": "UTC+05:00",
      "city": "Tashkent",
      "country": "Uzbekistan"
    },
    {
      "utc_offset": "UTC+06:00",
      "city": "Dhaka",
      "country": "Bangladesh"
    },
    {
      "utc_offset": "UTC+06:00",
      "city": "Almaty",
      "country": "Kazakhstan"
    },
    {
      "utc_offset": "UTC+07:00",
      "city": "Bangkok",
      "country": "Thailand"
    },
    {
      "utc_offset": "UTC+07:00",
      "city": "Ho Chi Minh City",
      "country": "Vietnam"
    },
    {
      "utc_offset": "UTC+08:00",
      "city": "Beijing",
      "country": "China"
    },
    {
      "utc_offset": "UTC+08:00",
      "city": "Perth",
      "country": "Australia"
    },
    {
      "utc_offset": "UTC+09:00",
      "city": "Tokyo",
      "country": "Japan"
    },
    {
      "utc_offset": "UTC+09:00",
      "city": "Seoul",
      "country": "South Korea"
    },
    {
      "utc_offset": "UTC+10:00",
      "city": "Sydney",
      "country": "Australia"
    },
    {
      "utc_offset": "UTC+10:00",
      "city": "Port Moresby",
      "country": "Papua New Guinea"
    },
    {
      "utc_offset": "UTC+11:00",
      "city": "Honiara",
      "country": "Solomon Islands"
    },
    {
      "utc_offset": "UTC+11:00",
      "city": "Port Vila",
      "country": "Vanuatu"
    },
    {
      "utc_offset": "UTC+12:00",
      "city": "Wellington",
      "country": "New Zealand"
    },
    {
      "utc_offset": "UTC+12:00",
      "city": "Suva",
      "country": "Fiji"
    }
  ]
}
