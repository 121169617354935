export const label = {
  TopMenu: {
    Search: "Buscar en ClearSale",
  },
  UserMenu: {
    language: {
      title: "Idioma",
      english: "English",
      portuguese: "Português",
      spanish: "Español",
    },
    timezone: "Zona horaria",
    theme: {
      title: "Tema",
      dark: "Oscuro",
      light: "Claro",
    },
    signout: "Desconectar",
  },
};
