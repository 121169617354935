export const label = {
  TopMenu: {
    Search: "Procurar na ClearSale",
  },
  UserMenu: {
    language: {
      title: "Idioma",
      english: "English",
      portuguese: "Português",
      spanish: "Español",
    },
    timezone: "Fuso horário",
    theme: {
      title: "Tema",
      dark: "Escuro",
      light: "Claro",
    },
    signout: "Sair",
  },
};
