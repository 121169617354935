import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-cs_background" }
const _hoisted_2 = {
  class: "d-flex align-center ga-8 w-100 ma-auto px-4",
  style: {"position":"relative"},
  id: "menu"
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "d-flex align-center justify-space-between w-100" }
const _hoisted_5 = { class: "d-flex align-center" }

import { useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";

import UserMenu from "./UserMenu/UserMenu.vue";
import AppsMenu from "./AppsMenu/AppsMenu.vue";

import imgShield from "@/assets/logo_shield.svg";
import imgClearsale from "@/assets/logo_mobile.png";
import imgNexusLogo from "@/assets/logos/logo_nexus.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'OneMenu',
  props: {
    showLogo: { type: Boolean, default: true }
  },
  setup(__props: any) {

const router = useRouter();


const appsMenu = ref(false);
const userMenu = ref(false);

const routerMap = {
  nexus: {
    img: imgNexusLogo,
    url: "/nexus",
    altImg: "Nexus Logo",
  },
  shield: {
    img: imgShield,
    url: "/shield",
    altImg: "Shield Logo",
  },
  app: {
    img: imgClearsale,
    url: "/app",
    altImg: "Clearsale Logo",
  },
} as const;

const pathSplit = ref<keyof typeof routerMap>("app");

function handleDispathCustomEventChangeRoute(path: string, name?: string) {
  const event = new CustomEvent("changeRoute", {
    detail: {
      path,
      name,
    },
  });
  window.dispatchEvent(event);
}

const redirectToRouteByApp = computed(
  () => routerMap[(pathSplit.value as keyof typeof routerMap) || "app"].url
);
const getImageByApp = computed(() => {
  return routerMap[(pathSplit.value as keyof typeof routerMap) || "app"].img;
});

const getAltImgByApp = computed(
  () => routerMap[(pathSplit.value as keyof typeof routerMap) || "app"].altImg
);

const handleLogoClick = () => {
  if (window.scrollY > 0) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    handleDispathCustomEventChangeRoute(redirectToRouteByApp.value);
  }
};

router.beforeEach((_, __, next) => {
  loadSplitPathApplicationHistory();
  next();
});
function loadSplitPathApplicationHistory() {
  const currentState = window.history.state;

  if (currentState && typeof currentState.current === "string") {
    const [, applicationSplitPath] = currentState.current.split("/");

    if (applicationSplitPath && typeof applicationSplitPath === "string") {
      const [cleanApplicationSplitPath] = applicationSplitPath.split("?");

      pathSplit.value = cleanApplicationSplitPath as keyof typeof routerMap;
    }
  }
}
onMounted(() => {
  loadSplitPathApplicationHistory();
});

watch(appsMenu, () => {
  if (appsMenu.value) {
    userMenu.value = false;
  }
});

watch(userMenu, () => {
  if (userMenu.value) {
    appsMenu.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showLogo)
        ? _renderSlot(_ctx.$slots, "logo", { key: 0 }, () => [
            _createElementVNode("img", {
              src: getImageByApp.value,
              alt: getAltImgByApp.value,
              class: "logo__img",
              height: "48px",
              onClick: handleLogoClick
            }, null, 8, _hoisted_3)
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
          ])
        ]),
        _createElementVNode("nav", null, [
          _renderSlot(_ctx.$slots, "nav", {}, () => [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "links", {}, () => [
                _createVNode(AppsMenu, {
                  modelValue: appsMenu.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((appsMenu).value = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(UserMenu, {
                  modelValue: userMenu.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userMenu).value = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})