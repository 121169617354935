<template>
  <OneMenu
    class="py-4"
    v-if="!activeSSO || (sessionValidate && showMenu)"
  ></OneMenu>
  <div v-else style="height: 80px"></div>
</template>

<script lang="ts" setup>
import { onUnmounted, onBeforeMount, watch, onMounted, ref } from "vue";
import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import imgClearsale from "@/assets/logo_mobile.png";

import { MergeHead, ActiveHeadEntry, useHead, UseHeadInput } from "@unhead/vue";
import { useTheme } from "vuetify/lib/framework.mjs";
import { i18n } from "./plugins/i18n";
import { lib } from "@clearsale/one-lib-auth";

import type {
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { customEvents } from "@clearsale/one-lib-events";

const activeSSO = process.env.VUE_APP_ENV !== "development";

let theme = useTheme();

const showMenu = ref(false);
const sessionValidate = ref(false);

const headInfo = useHead({
  title: "One",
  link: [{ rel: "icon", href: imgClearsale, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }
});

function loadAppConfig() {
  customEvents.appLoadedEvent.listener(async (event) => {
    if ((event.detail as { appName: string }).appName.toLowerCase() === "one") {
      showMenu.value = true;
      sessionValidate.value = lib.Authenticate.validateSession("one");
    }
  });

  customEvents.appLoadedEvent.listener(async (event) => {
    if (
      (event.detail as { appName: string }).appName.toLowerCase() ===
      "others-apps"
    ) {
      showMenu.value = true;
      sessionValidate.value = lib.Authenticate.validateSession("one");
    }
  });
}

onMounted(() => {
  loadAppConfig();
});

onUnmounted(() => {
  headInfo.dispose();
});

watch(
  () => theme.global.name,
  () => {
    customEvents.themeEvents.dispatch(
      theme.global.name.value as THEME_CHANGED_PAYLOAD
    );
  },
  { deep: true, immediate: true }
);

watch(
  () => i18n.global.locale,
  () => {
    customEvents.languageEvent.dispatch(
      i18n.global.locale.value as LANGUAGE_CHANGED_PAYLOAD
    );
  },
  { deep: true, immediate: true }
);
</script>
