export const linksExample: NewMenu.Link[] = [
  {
    id: "Example",
    label: '',
    routerName: "",
    order: 1,
    subLinks: [
      {
        id: "Example",
        label: '',
        routerName: "",
        order: 1,
        subLinks: [],
      },
      {
        id: "Example2",
        label: '',
        order: 2,
        routerName: "",
        subLinks: [
          {
            id: "Example2",
            label: '',
            order: 1,
            routerName: "",
            subLinks: [],
          },
          {
            id: "Example3",
            label: '',
            routerName: "Example3View",
            order: 2,
            subLinks: [],
          },
          {
            id: "Example4",
            label: '',
            routerName: "",
            order: 3,
            subLinks: [],
          },
          {
            id: "Example5",
            label: '',
            routerName: "Example5View",
            order: 4,
            subLinks: [],
          },
        ],
      },
    ],
  },
  {
    id: "Test",
    label: '',
    routerName: "TestView",
    order: 2,
    subLinks: [],
  },
  { id: "ExampleTest", label: '', routerName: "ExampleTestView", order: 3, subLinks: [], },
]
