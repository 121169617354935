export function useLocalStorage(appStorage = 'one') {
  function setStorage(keyStorage: string, value: string) {
    window.localStorage.setItem(`${appStorage}/${keyStorage}`, value)
  }

  function getStorage(keyStorage: string) {
    return window.localStorage.getItem(`${appStorage}/${keyStorage}`)
  }

  function removeStorage(keyStorage: string) {
    return window.localStorage.removeItem(`${appStorage}/${keyStorage}`)
  }

  function clearStorage() {
    window.localStorage.clear()
  }

  return {
    setStorage,
    getStorage,
    removeStorage,
    clearStorage
  }
}
