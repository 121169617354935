import { getThemeByName, ThemeName, tokens } from "@clearsale/tokens-ds";

// Caso precise adicionar novas cores customizadas, adicione no objeto abaixo
const customColorsLocal = {
  ...getTokensWithoutPrefixMDUI()
}

export function renameHiphenToUnderscore(obj: Record<string, unknown>) {
  const newObj: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(/-/g, '_');
    newObj[newKey] = obj[key];
  });
  return newObj;
}

export function renameWithPrefixMdui(obj: Record<string, unknown>) {
  const newObj: Record<string, string> = {};
  Object.keys(obj).forEach((key) => {
    newObj[`--mdui-${key}`] = obj[key] as string;
  });
  return newObj;
}

export function addPrefixClearsaleToPalette(obj: Record<string, unknown>) {
  const newObj: Record<string, string> = {};
  Object.keys(obj).forEach((key) => {
    newObj[`cs_${key}`] = obj[key] as string;
  });
  return newObj;
}

export function getTokensWithoutPrefixMDUI() {
  const newObj: Record<string, string> = {};
  Object.keys(tokens.palette).forEach((key) => {
    const keyWhithoutPrefixMdui = key.replace('--mdui-', '');
    newObj[keyWhithoutPrefixMdui] = tokens.palette[key as keyof typeof tokens.palette];
  });
  return newObj;
}

export function loadThemeByName(name: ThemeName = 'dark') {
  return {
    ...getThemeByName(name),
    ...addPrefixClearsaleToPalette(renameHiphenToUnderscore(getThemeByName(name))),
    ...addPrefixClearsaleToPalette(renameHiphenToUnderscore(customColorsLocal)),
    ...customColorsLocal
  }
}
