import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Transition as _Transition, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cs__menu w-100 d-flex flex-column"
}
const _hoisted_2 = { class: "d-flex flex-column rounded-lg overflow-y-auto" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex align-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src", "width", "height"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "d-flex align-center ga-2" }
const _hoisted_10 = { class: "d-flex align-center" }
const _hoisted_11 = { key: 0 }

import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import { linksExample } from "../constants/Menu";
import { useCustomMenu } from "../useCustomMenu";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomMenuDesktop',
  props: /*@__PURE__*/_mergeModels({
    links: { default: () => linksExample },
    hero: { type: Boolean, default: true }
  }, {
    "modelValue": { default: false },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["routerTo", "selectLink"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const modelMenu = _useModel(__props, "modelValue");
const { currentLinksRef, handleCloseMenu, handleRouterTo, renderCurrentLinks } =
  useCustomMenu({ emits, props, model: modelMenu });

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      icon: "",
      variant: "text",
      color: "cs_surface_variant",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (modelMenu.value = !modelMenu.value), ["stop"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          icon: "mdi-account-circle",
          class: "text-cs_on_surface_variant"
        })
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (modelMenu.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(renderCurrentLinks), (link) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: link.label
                    }, [
                      (_ctx.hero && link.order && link.order === 1)
                        ? _renderSlot(_ctx.$slots, "hero", {
                            key: 0,
                            classStyle: "pa-4 bg-cs_secondary_container cursor-pointer"
                          }, () => [
                            _createElementVNode("div", {
                              onClick: _withModifiers(($event: any) => (_unref(handleRouterTo)(link)), ["stop"]),
                              class: _normalizeClass(["pa-4 bg-cs_secondary_container cursor-pointer d-flex align-center justify-space-between", `order-${link.order}`])
                            }, [
                              _createElementVNode("div", _hoisted_4, [
                                (_unref(currentLinksRef).length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createVNode(_component_v_icon, { icon: "mdi-menu-left" })
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["d-flex align-center ga-4", link.className])
                                }, [
                                  (link.img)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: link.img.name,
                                        alt: "img",
                                        width: link.img.size,
                                        height: link.img.size
                                      }, null, 8, _hoisted_6))
                                    : (link.icon)
                                      ? (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 1,
                                          icon: link.icon.name,
                                          size: link.icon.size
                                        }, null, 8, ["icon", "size"]))
                                      : _createCommentVNode("", true),
                                  _createElementVNode("div", null, _toDisplayString(link.label), 1)
                                ], 2)
                              ]),
                              (link.subLinks.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_v_icon, { icon: "mdi-menu-right" })
                                  ]))
                                : _createCommentVNode("", true)
                            ], 10, _hoisted_3)
                          ])
                        : (link.id === 'separator')
                          ? (_openBlock(), _createBlock(_component_v_divider, {
                              key: 1,
                              class: _normalizeClass(`order-${link.order}`),
                              thickness: 1
                            }, null, 8, ["class"]))
                          : _renderSlot(_ctx.$slots, "items", {
                              key: 2,
                              links: _unref(renderCurrentLinks),
                              link: link,
                              classStyle: "pa-4 bg-surface-variant  cursor-pointer"
                            }, () => [
                              _createVNode(_component_v_hover, null, {
                                default: _withCtx(({ isHovering, props }) => [
                                  _createElementVNode("div", _mergeProps({ ref_for: true }, props, {
                                    onClick: _withModifiers(($event: any) => (_unref(handleRouterTo)(link)), ["stop"]),
                                    class: ["pa-4 bg-surface-variant cursor-pointer d-flex align-center flex-column", [
                        `order-${link.order}`,
                        { 'bg-cs_surface_container': isHovering },
                      ]]
                                  }), [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["d-flex align-center justify-space-between w-100", link.className])
                                    }, [
                                      _createElementVNode("div", _hoisted_9, [
                                        (link.icon)
                                          ? (_openBlock(), _createBlock(_component_v_icon, {
                                              key: 0,
                                              icon: link.icon ? link.icon.name : '',
                                              size: link.icon ? link.icon.size : '24px'
                                            }, null, 8, ["icon", "size"]))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_10, _toDisplayString(link.label), 1)
                                      ]),
                                      (link.subLinks.length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                            _createVNode(_component_v_icon, { icon: "mdi-menu-right" })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 2)
                                  ], 16, _hoisted_8)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                    ], 64))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ])), [
    [_unref(vClickOutside), _unref(handleCloseMenu)]
  ])
}
}

})