import "@clearsale/material-ds/dist/clearsale-material-ds/clearsale-material-ds.css";

import { createHead } from "@unhead/vue";
import singleSpaVue from 'single-spa-vue';
import { createApp, h } from 'vue';

import { handlersHTTP } from '@/api/mocks/server/handlersHTTP';

import { i18n } from '@/plugins/i18n';
import { pinia } from '@/plugins/store';
import vuetify from '@/plugins/vuetify';
import { loadFonts } from '@/plugins/webfontloader';

import router from '@/router';

import App from '@/App.vue';

loadFonts();

const head = createHead();

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {

    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  handleInstance(app) {
    app.use(head);
    app.use(vuetify)
    app.use(i18n)
    app.use(pinia)
    app.use(router);
  },
});

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

function dispatchCustomEventLoadMockAPI() {
  const event = new CustomEvent('loadMockAPI', {
    detail: {
      handlers: handlersHTTP,
    },
  });

  window.dispatchEvent(event);
}

dispatchCustomEventLoadMockAPI();
